import i18n from "../../../i18n";
import PropTypes from "prop-types";

import WarehouseIcon from "@mui/icons-material/Warehouse";
import ApartmentIcon from "@mui/icons-material/Apartment";
import StairsOutlinedIcon from "@mui/icons-material/StairsOutlined";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import GradingIcon from "@mui/icons-material/Grading";
import GroupsIcon from "@mui/icons-material/Groups";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DraftsIcon from "@mui/icons-material/Drafts";
import VerifiedIcon from "@mui/icons-material/Verified";
import SubjectIcon from "@mui/icons-material/Subject";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import BusinessIcon from "@mui/icons-material/Business";
import StarIcon from "@mui/icons-material/Star";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HandshakeIcon from "@mui/icons-material/Handshake";
import BarChartIcon from "@mui/icons-material/BarChart";
import SecurityIcon from "@mui/icons-material/Security";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ChecklistIcon from "@mui/icons-material/Checklist";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import DescriptionIcon from "@mui/icons-material/Description";
import MoneyIcon from '@mui/icons-material/Money';

import MiniDrawer from "../MiniDrawer";
import { useNavigate } from "react-router-dom";

import hasUserPermission, {
  IS_K,
  IS_KT,
  IS_OM,
  IS_T,
  IS_TB,
  IS_RR,
  IS_G,
  IS_ADMIN,
  IS_LEGAL,
  ALL_PERMISSION_LIST,
  IS_FINANCE,
  IS_ACCOUNT,
  IS_WAREHOUSE,
  IS_CONTROLLING,
  IS_COMMERCE,
  IS_USERS_ADMIN,
  IS_TENANTS_ADMIN,
  IS_OBJECTS_ADMIN,
  IS_STD_TICKETS_ADMIN,
  IS_AUTO_TICKETS_ADMIN,
  IS_WAREHOUSE_ADMIN,
  IS_SCM,
  IS_REPORT_FM,
  IS_REPORT_SAFETY,
  IS_POST_ADMIN,
} from "../../../helpers/userPermissions";
import { useAuth } from "../../../context/auth";
import { APP_STAGE } from "../../../helpers/constants";

export const NAVIGATION_DRAWER_TYPE_ALL = "all";
export const NAVIGATION_DRAWER_TYPE_TICKETS = "tickets";
export const NAVIGATION_DRAWER_TYPE_GUARANTEE = "guarantee";
export const NAVIGATION_DRAWER_TYPE_OFFERS = "offers";
export const NAVIGATION_DRAWER_TYPE_RR = "rr";
export const NAVIGATION_DRAWER_TYPE_LEGAL = "legal";
export const NAVIGATION_DRAWER_TYPE_PAYMENT = "paymnet";
export const NAVIGATION_DRAWER_TYPE_CORRESPONDENCE = "correspondence";
export const NAVIGATION_DRAWER_TYPE_REPORTS = "reports";
export const NAVIGATION_DRAWER_TYPE_ADMIN = "admin";
export const NAVIGATION_DRAWER_TYPE_WAREHOUSES = "warehouses";
export const NAVIGATION_DRAWER_TYPE_RELEASES = "releases";
export const NAVIGATION_DRAWER_TYPE_SUBCONTRACTOR = "subcontractor";
export const NAVIGATION_DRAWER_TYPE_DOCUMENTS = "documents";

const topDrawerActionIcons = [
  // {
  //   label: "Budynki",
  //   icon: <ApartmentIcon />,
  //   link: "#",
  //   pageName: "",
  //   permissionList: [IS_K, IS_ADMIN],
  // },
  // {
  //   label: "Kondygnacje",
  //   icon: <StairsOutlinedIcon />,
  //   link: "#",
  //   pageName: "",
  //   permissionList: [IS_K, IS_ADMIN],
  // },
  // {
  //   label: "Przestrzenie",
  //   icon: <AppRegistrationOutlinedIcon />,
  //   link: "#",
  //   pageName: "",
  //   permissionList: [IS_K, IS_ADMIN],
  // },
  {
    label: i18n.t("drawer.navigation_drawer.rr_panel"),
    icon: <Diversity3Icon />,
    link: "/rr_panel/",
    pageName: "rr_panel_page",
    permissionList: [IS_ADMIN, IS_RR],
    drawerType: NAVIGATION_DRAWER_TYPE_RR,
  },
  {
    label: i18n.t("drawer.navigation_drawer.feedback"),
    icon: <StarIcon />,
    link: "/feedback/",
    pageName: "feedback",
    permissionList: [IS_RR, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_RR,
  },
  {
    label: i18n.t("drawer.navigation_drawer.rr_panel_complains"),
    icon: <NewReleasesIcon />,
    link: "/rr_panel_complains/",
    pageName: "rr_panel_complains",
    permissionList: [IS_RR, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_RR,
  },
  {
    label: i18n.t("drawer.navigation_drawer.rr_admin"),
    icon: <SecurityIcon />,
    link: "/rr_admin/",
    pageName: "rr_admin_page",
    permissionList: [IS_ADMIN, IS_RR],
    drawerType: NAVIGATION_DRAWER_TYPE_RR,
  },
  {
    label: i18n.t("drawer.navigation_drawer.item_tree"),
    icon: <AccountTreeIcon />,
    link: "/item_tree/",
    pageName: "item_tree",
    permissionList: [IS_SCM, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_WAREHOUSES,
  },
  {
    label: i18n.t("drawer.navigation_drawer.item_tree"),
    icon: <AccountTreeIcon />,
    link: "/item_tree/",
    pageName: "item_tree",
    permissionList: [],
    drawerType: NAVIGATION_DRAWER_TYPE_OFFERS,
  },
];

const middleDrawerActionIcons = [
  {
    label: i18n.t("drawer.navigation_drawer.my_tickets"),
    icon: <SmsFailedIcon />,
    link: "/my_tickets/",
    pageName: "my_tickets",
    permissionList: [],
    drawerType: NAVIGATION_DRAWER_TYPE_TICKETS,
  },
  {
    label: i18n.t("drawer.navigation_drawer.guarantee"),
    icon: <VerifiedIcon />,
    link: "/guarantee/",
    pageName: "guarantee",
    permissionList: [IS_G, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_GUARANTEE,
  },
  {
    label: i18n.t("drawer.navigation_drawer.ticket_management"),
    icon: <GroupsIcon />,
    link: "/ticket_orders/",
    pageName: "ticket_orders",
    permissionList: [IS_K, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_TICKETS,
  },
  {
    label: i18n.t("drawer.navigation_drawer.my_orders"),
    icon: <SubjectIcon />,
    link: "/my_orders/",
    pageName: "my_orders",
    permissionList: [IS_KT, IS_ADMIN, IS_TB, IS_K],
    drawerType: NAVIGATION_DRAWER_TYPE_TICKETS,
  },
  {
    label: i18n.t("drawer.navigation_drawer.order_management"),
    icon: <GradingIcon />,
    link: "/orders/",
    pageName: "orders",
    permissionList: [IS_KT, IS_K, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_TICKETS,
  },
  {
    label: i18n.t("drawer.navigation_drawer.my_tasks"),
    icon: <HowToRegIcon />,
    link: "/my_tasks/",
    pageName: "my_tasks",
    permissionList: [IS_T, IS_TB, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_TICKETS,
  },

  {
    label: i18n.t("drawer.navigation_drawer.task_management"),
    icon: <ChecklistIcon />,
    link: "/task_management/",
    pageName: "task_management",
    permissionList: [IS_KT, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_TICKETS,
  },
  {
    label: i18n.t("drawer.navigation_drawer.planner"),
    icon: <CalendarMonthIcon />,
    link: "/planner/",
    pageName: "planner_page",
    permissionList: [IS_KT, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_TICKETS,
  },
  {
    label: i18n.t("drawer.navigation_drawer.my_offers"),
    icon: <DraftsIcon />,
    link: "/my_offers/",
    pageName: "my_offers",
    permissionList: [IS_OM, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_TICKETS,
  },
  {
    label: i18n.t("drawer.navigation_drawer.offer_management"),
    icon: <DraftsIcon />,
    link: "/offers/",
    pageName: "offers",
    permissionList: [IS_K, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_OFFERS,
  },
  {
    label: i18n.t("drawer.navigation_drawer.join_offers"),
    icon: <DraftsIcon />,
    link: "/join_offers/",
    pageName: "offersJoin",
    permissionList: [IS_KT, IS_TB, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_OFFERS,
  },
  {
    label: i18n.t("drawer.navigation_drawer.accept_offers"),
    icon: <DraftsIcon />,
    link: "/accept_offers/",
    pageName: "offersRRAccept",
    permissionList: [IS_RR, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_OFFERS,
  },
  {
    label: i18n.t("drawer.navigation_drawer.my_documents"),
    icon: <InsertDriveFileOutlinedIcon />,
    link: "/my_documents/",
    pageName: "my_documents",
    permissionList: [],
    drawerType: NAVIGATION_DRAWER_TYPE_TICKETS,
  },
  {
    label: i18n.t("drawer.navigation_drawer.grt_report"),
    icon: <BarChartIcon />,
    link: "/grt_report/",
    pageName: "grt_report",
    permissionList: [IS_ACCOUNT, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_PAYMENT,
  },
  {
    label: i18n.t("drawer.navigation_drawer.rcp_time_tracking"),
    icon: <TimerOutlinedIcon />,
    link: "/rcp_time_tracking/",
    pageName: "rcp_time_tracking",
    permissionList: [IS_KT, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_REPORTS,
  },
  {
    label: i18n.t("drawer.navigation_drawer.security_reports"),
    icon: <SecurityIcon />,
    link: "/tickets_per_time_report/",
    pageName: "tickets_per_time_report",
    permissionList: [IS_ADMIN, IS_REPORT_SAFETY],
    drawerType: NAVIGATION_DRAWER_TYPE_REPORTS,
  },
  {
    label: i18n.t("drawer.navigation_drawer.dun_reports"),
    icon: <BarChartIcon />,
    link: "/tickets_per_location_day_report/",
    pageName: "tickets_per_location_day_report",
    permissionList: [IS_ADMIN, IS_REPORT_FM],
    drawerType: NAVIGATION_DRAWER_TYPE_REPORTS,
  },

  {
    label: i18n.t("drawer.navigation_drawer.admin_users"),
    icon: <GroupsIcon />,
    link: "/admin_users/",
    pageName: "admin_users",
    permissionList: [IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_ADMIN,
  },
  {
    label: i18n.t("drawer.navigation_drawer.permissions"),
    icon: <PermIdentityIcon />,
    link: "/admin_permissions/",
    pageName: "admin_permissions",
    permissionList: [IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_ADMIN,
  },
  {
    label: i18n.t("drawer.navigation_drawer.admin_tenants"),
    icon: <ApartmentIcon />,
    link: "/admin_tenants/",
    pageName: "admin_tenants",
    permissionList: [IS_ADMIN, IS_TENANTS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_ADMIN,
  },
  {
    label: i18n.t("drawer.navigation_drawer.admin_locations"),
    icon: <StairsOutlinedIcon />,
    link: "/admin_locations/",
    pageName: "admin_locations",
    permissionList: [IS_ADMIN, IS_OBJECTS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_ADMIN,
  },
  {
    label: i18n.t("drawer.navigation_drawer.admin_std_tickets_orders"),
    icon: <AccountTreeIcon />,
    link: "/admin_std_tickets_orders/",
    pageName: "admin_std_tickets_orders",
    permissionList: [IS_ADMIN, IS_STD_TICKETS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_ADMIN,
  },
  {
    label: i18n.t("drawer.navigation_drawer.admin_std_tickets_orders_automat"),
    icon: <DeviceHubIcon />,
    link: "/admin_std_tickets_orders_automat/",
    pageName: "admin_std_tickets_orders_automat",
    permissionList: [IS_ADMIN, IS_AUTO_TICKETS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_ADMIN,
  },
  {
    label: i18n.t("drawer.navigation_drawer.stocks"),
    icon: <WarehouseIcon />,
    link: "/stocks/",
    pageName: "stocks",
    permissionList: [IS_WAREHOUSE, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_WAREHOUSES,
  },
  {
    label: i18n.t("drawer.navigation_drawer.material_passport"),
    icon: <ContentPasteIcon />,
    link: "/material_passport/",
    pageName: "material_passport",
    permissionList: [],
    drawerType: NAVIGATION_DRAWER_TYPE_WAREHOUSES,
  },
  {
    label: i18n.t("drawer.navigation_drawer.warehouses_transaction"),
    icon: <RestorePageIcon />,
    link: "/transactions/",
    pageName: "warehouses_transaction",
    permissionList: [IS_WAREHOUSE, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_WAREHOUSES,
  },
  {
    label: i18n.t("drawer.navigation_drawer.po_registry"),
    icon: <ShoppingCartIcon />,
    link: "/po_registry/",
    pageName: "po_registry",
    permissionList: [IS_SCM, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_WAREHOUSES,
  },
  {
    label: i18n.t("drawer.navigation_drawer.po_invoices"),
    icon: <ReceiptIcon />,
    link: "/po_invoices/",
    pageName: "po_invoices",
    permissionList: [IS_SCM, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_WAREHOUSES,
  },
  {
    label: i18n.t("drawer.navigation_drawer.buffers"),
    icon: <ChecklistIcon />,
    link: "/buffers/",
    pageName: "buffers",
    permissionList: [IS_WAREHOUSE, IS_SCM, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_WAREHOUSES,
  },
  {
    label: i18n.t("drawer.navigation_drawer.price_list"),
    icon: <MoneyIcon />,
    link: "/price_list/",
    pageName: "price_list",
    permissionList: [],
    drawerType: NAVIGATION_DRAWER_TYPE_TICKETS,
  },
];

const bottomDrawerActionIcons = [
  {
    label: i18n.t("drawer.navigation_drawer.owners"),
    icon: <BusinessIcon />,
    link: "/owners/",
    pageName: "owners",
    permissionList: [
      IS_LEGAL,
      IS_CONTROLLING,
      IS_FINANCE,
      IS_ACCOUNT,
      IS_COMMERCE,
      IS_ADMIN,
    ],
    drawerType: NAVIGATION_DRAWER_TYPE_LEGAL,
  },
  {
    label: i18n.t("drawer.navigation_drawer.subcontractors"),
    icon: <Diversity3Icon />,
    link: "/subcontractors/",
    pageName: "subcontractors",
    permissionList: [IS_K, IS_KT, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_SUBCONTRACTOR,
  },

  {
    label: i18n.t("drawer.navigation_drawer.correspondence"),
    icon: <MarkunreadMailboxIcon />,
    link: "/correspondence/",
    pageName: "correspondence",
    permissionList: [IS_LEGAL, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_CORRESPONDENCE,
  },
  {
    label: i18n.t("drawer.navigation_drawer.correspondence_admin_panel"),
    icon: <SecurityIcon />,
    link: "/correspondence_admin/",
    pageName: "correspondence_admin",
    permissionList: [IS_POST_ADMIN, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_CORRESPONDENCE,
  },
  {
    label: i18n.t("drawer.navigation_drawer.agreements"),
    icon: <HandshakeIcon />,
    link: "/agreements/",
    pageName: "agreements",
    permissionList: [IS_LEGAL, IS_CONTROLLING, IS_COMMERCE, IS_ADMIN],
    drawerType: ![APP_STAGE.PROD].includes(
      window._env_.APP_STAGE.toString().toLowerCase()
    )
      ? NAVIGATION_DRAWER_TYPE_LEGAL
      : null,
  },
  {
    label: i18n.t("drawer.navigation_drawer.agreement_guarantees"),
    icon: <MonetizationOnIcon />,
    link: "/agreement_guarantees/",
    pageName: "agreement_guarantees",
    permissionList: [IS_LEGAL, IS_CONTROLLING, IS_COMMERCE, IS_ADMIN],
    drawerType: ![APP_STAGE.PROD].includes(
      window._env_.APP_STAGE.toString().toLowerCase()
    )
      ? NAVIGATION_DRAWER_TYPE_LEGAL
      : null,
  },
  {
    label: i18n.t("drawer.navigation_drawer.admin_panel"),
    icon: <AdminPanelSettingsIcon />,
    link: "/admin_users/",
    pageName: "admin_page",
    permissionList: [IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_TICKETS,
  },
  {
    label: i18n.t("drawer.navigation_drawer.releases"),
    icon: <AddBusinessIcon />,
    link: "/releases/",
    pageName: "releases",
    permissionList: [IS_COMMERCE, IS_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_RELEASES,
  },
  {
    label: i18n.t("drawer.navigation_drawer.warehouse_admin"),
    icon: <SecurityIcon />,
    link: "/warehouse_admin/",
    pageName: "warehouse_admin",
    permissionList: [IS_ADMIN, IS_WAREHOUSE_ADMIN],
    drawerType: NAVIGATION_DRAWER_TYPE_WAREHOUSES,
  },
  {
    label: i18n.t("drawer.navigation_drawer.service_passport"),
    icon: <ContentPasteIcon />,
    link: "/service_passport/",
    pageName: "service_passport",
    permissionList: [],
    drawerType: NAVIGATION_DRAWER_TYPE_OFFERS,
  },

  {
    label: i18n.t("drawer.navigation_drawer.material_passport"),
    icon: <ContentPasteIcon />,
    link: "/material_passport/",
    pageName: "material_passport",
    permissionList: [],
    drawerType: NAVIGATION_DRAWER_TYPE_OFFERS,
  },
  {
    label: i18n.t("drawer.navigation_drawer.docs_restricted"),
    icon: <DescriptionIcon />,
    link: "/docs_restricted/",
    pageName: "docs_restricted",
    permissionList: [IS_ADMIN, IS_KT, IS_K, IS_TB],
    drawerType: NAVIGATION_DRAWER_TYPE_DOCUMENTS,
  },
];

function NavigationDrawer(props) {
  const navigate = useNavigate();
  const { user } = useAuth();

  const getDrawerActionIconsContent = (drawerActionIcons) => {
    return drawerActionIcons
      .filter(
        (drawerActionIcon) =>
          hasUserPermission(drawerActionIcon.permissionList, user) &&
          (drawerActionIcon.drawerType === NAVIGATION_DRAWER_TYPE_ALL ||
            drawerActionIcon.drawerType === props.drawerType ||
            drawerActionIcon.drawerType in props.extraDrawerTypes)
      )
      .map((drawerActionIcon) => {
        const { pageName, link, permissionList, ...rest } = drawerActionIcon;
        return {
          ...rest,
          callback: () => window.location.replace(link),
          isActive: pageName === props.pageName,
        };
      });
  };

  const topDrawerIcons = getDrawerActionIconsContent(topDrawerActionIcons);
  const middleDrawerIcons = getDrawerActionIconsContent(
    middleDrawerActionIcons
  );
  const bottomDrawerIcons = getDrawerActionIconsContent(
    bottomDrawerActionIcons
  );
  return (
    <MiniDrawer
      initialOpen={true}
      topDrawerActionIcons={topDrawerIcons}
      showTopDrawerActionIcons={topDrawerIcons.length > 0}
      middleDrawerActionIcons={middleDrawerIcons}
      showMiddleDrawerActionIcons={middleDrawerIcons.length > 0}
      bottomDrawerActionIcons={bottomDrawerIcons}
      showBottomDrawerActionIcons={bottomDrawerIcons.length > 0}
    >
      {props.children}
    </MiniDrawer>
  );
}

export default NavigationDrawer;

NavigationDrawer.propTypes = {
  pageName: PropTypes.string,
  drawerType: PropTypes.oneOf([
    NAVIGATION_DRAWER_TYPE_TICKETS,
    NAVIGATION_DRAWER_TYPE_GUARANTEE,
    NAVIGATION_DRAWER_TYPE_RELEASES,
  ]),
  extraDrawerTypes: PropTypes.array,
};

NavigationDrawer.defaultProps = {
  drawerType: NAVIGATION_DRAWER_TYPE_TICKETS,
  extraDrawerTypes: [],
};
