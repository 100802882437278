import { useTranslation } from "react-i18next";
import { makeRequest } from "../helpers/makeRequest";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useSnackbarAlert } from "../context/snackbarAlert";
import {
  enclosuresForOrderUrl,
  enclosuresForTicketInlcudeOrdersUrl,
  enclosureDownloadUrl,
  eclosureDataUrl,
  enclosureTODataUrl,
  createEnclosureUrl,
  createEnclosureTOUrl,
  saveFileUrl,
  enclosureFavoriteUrl,
  enclosureFavoriteForEnclosureUrl,
  enclosureFilteringDataUrl,
  createManyEnclosureUrl,
  enclosuresForOfferUrl,
  offerEnclosureUrl,
  enclosureUploadSasTokenUrl,
  partEnclosureUrl,
  enclosuresForPartUrl,
  enclosuresKindPartUrl,
} from "../helpers/apiUrls";
import {
  getFieldsFromObject,
  prepareUrlWithQueryParams,
  getErrorMsg,
  isEmptyValue,
} from "../helpers/methods";
import useThumbnailService from "./thumbnailService";

export default function useFileService() {
  const axiosPrivate = useAxiosPrivate();

  const snackbarAlert = useSnackbarAlert();

  const { t } = useTranslation();

  const { convertToThumbnail } = useThumbnailService();

  const downloadFile = (fileUrl, fileName, data = undefined, searchParams) => {
    var anxios;
    fileUrl = prepareUrlWithQueryParams(fileUrl, searchParams);
    if (data) {
      anxios = axiosPrivate.post(fileUrl, data, { responseType: "blob" });
    } else {
      anxios = axiosPrivate.get(fileUrl, { responseType: "blob" });
    }
    anxios
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/stream" })
        );
        downloadFileByBlobUrl(url, fileName);
      })
      .catch((error) => {
        error.response.data
          .text()
          .then((responseTxt) => {
            snackbarAlert.openErrorSnackbarAlert(
              getErrorMsg(
                JSON.parse(responseTxt),
                t("snackbar_alert.occurred_error_during_download_file")
              )
            );
          })
          .catch(() => {
            snackbarAlert.openErrorSnackbarAlert(
              t("snackbar_alert.occurred_error_during_download_file")
            );
          });
      });
  };

  const downloadFileByBlobUrl = (blobUrl, fileName) => {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = blobUrl;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(blobUrl);
  };

  const showFileByBlobUrl = (blobUrl) => {
    var a = document.createElement("a");
    a.href = blobUrl;
    a.target = "_blank";
    a.click();
  };

  const getEnclosuresForItemData = (
    itemId,
    searchParams = {},
    itemType = "ticket"
  ) => {
    if (itemId) {

      if (itemType === "ticket" || itemType === "ticket_order")
        return getEnclosuresForTicketIncludeOrdersData(itemId, searchParams);
      if (itemType === "order")
        return getEnclosuresForOrderData(itemId, searchParams);
      if (itemType === "offer") return getEnclosuresForOfferData(itemId);
      if (itemType === "part")
        return getEnclosuresForPartData(itemId)
    }
    return Promise.resolve([]);
  };

  const getEnclosuresForOrderData = (orderId, searchParams = {}) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(enclosuresForOrderUrl(orderId), searchParams)
    );
  };

  const getEnclosuresForTicketIncludeOrdersData = (
    ticketId,
    searchParams = {}
  ) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(
        enclosuresForTicketInlcudeOrdersUrl(ticketId),
        searchParams
      )
    );
  };

  const getEnclosuresForOfferData = (offerId) => {
    return makeRequest(axiosPrivate, enclosuresForOfferUrl(offerId));
  };

  const getEnclosuresForPartData = (partId) => {
    return makeRequest(axiosPrivate, enclosuresForPartUrl(partId));
  };

  const getEnclosuresKindPartData = () => {
    return makeRequest(axiosPrivate, enclosuresKindPartUrl);
  };

  const saveNewAttachment = (enclosure) => {
    const file = enclosure.file;
    delete enclosure.file;
    return new Promise((resolve, reject) => {
      saveFile(file, enclosure.enclosure_name)
        .then((result) => {
          enclosure.file_uploader = result.data.id;
          createAttachment(enclosure)
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const saveManyAttachments = async (
    enclosures,
    commonEnclosureData,
    searchParams
  ) => {
    let results = await uploadAttachments(enclosures, commonEnclosureData);
    let uploadedData = results["uploadedData"];
    let showWaring = results["showWaring"];
    return new Promise(async (resolve, reject) => {
      if (uploadedData.length > 0) {
        await createManyEnclosures(uploadedData, searchParams)
          .then((res) => {
            if (showWaring) {
              snackbarAlert.openWarningSnackbarAlert(
                t("snackbar_alert.occurred_warning_during_upload_file")
              );
            } else {
              snackbarAlert.openSuccessSnackbarAlert(
                t("snackbar_alert.enclosure_saved")
              );
              resolve(res);
            }
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_upload_file")
        );
      }
    });
  };

  const uploadAttachments = async (enclosures, commonEnclosureData) => {

    let showWarning = false;
    let getDataToGenerateTokens = enclosures.map(
      (enclosure) => enclosure.token_data
    );

    let results = await Promise.all([
      generateSASUploadTokens(getDataToGenerateTokens),
    ]);

    let mergedData = [];
    results[0].forEach((tokenData) => {
      let enclosureData = enclosures.find(
        (obj) => obj.token_data.unique_index === tokenData.unique_index
      );
      let contentType = enclosureData["token_data"]["content_type"];
      enclosureData["content_type"] = isEmptyValue(contentType) ? undefined : contentType;

      if (enclosureData) {
        mergedData.push({
          ...enclosureData,
          ...tokenData,
          ...commonEnclosureData,
        });
      }
    });


    let uploadedData = [];
    for (let data of mergedData) {
      delete data["token_data"];
      try {
        await uploadDataToAzureStorage(data.file, data.upload_url);
        if (data.miniature_upload_url) {
          convertToThumbnail(
            data.file,
            200,
            200,
            async function (thumbnailBlob) {
              await uploadDataToAzureStorage(
                thumbnailBlob,
                data.miniature_upload_url
              );
            }
          );
        }
        uploadedData.push(getDataToSave(data));
      } catch (error) {
        showWarning = true;
      }
    }
    return { uploadedData: uploadedData, showWaring: showWarning };
  };

  const getDataToSave = (enclosure) => {
    let isExternal = enclosure['enclosure_external']
    let enclosureSubcontractor = enclosure['enclosure_subcontractor'] ? enclosure['enclosure_subcontractor'] : false
    enclosure['enclosure_external'] = isExternal
    enclosure['enclosure_subcontractor'] = enclosureSubcontractor
    return enclosure
  }

  const createManyEnclosures = async (data, searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(createManyEnclosureUrl, searchParams),
      {
        method: "POST",
        data: data,
      }
    );
  };

  const createAttachment = (enclosure) => {
    return makeRequest(axiosPrivate, createEnclosureUrl, {
      method: "POST",
      data: enclosure,
    });
  };

  const copyAttachment = (enclosure) => {
    return makeRequest(axiosPrivate, createEnclosureTOUrl, {
      method: "POST",
      data: enclosure,
    });
  };

  const updateEnclosureData = (enclosureId, dataToSend) => {
    return makeRequest(axiosPrivate, eclosureDataUrl(enclosureId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const updateEnclosureTOData = (enclosureTOId, dataToSend) => {
    return makeRequest(axiosPrivate, enclosureTODataUrl(enclosureTOId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const updateEnclosureOfferData = (enclosureOfferId, dataToSend) => {
    return makeRequest(axiosPrivate, offerEnclosureUrl(enclosureOfferId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const updateEnclosurePartData = (enclosurePartId, dataToSend) => {
    return makeRequest(axiosPrivate, partEnclosureUrl(enclosurePartId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getEnclosureTOData = (enclosureTOId) => {
    if (enclosureTOId) {
      return makeRequest(axiosPrivate, enclosureTODataUrl(enclosureTOId));
    }
    return Promise.resolve({});
  };

  const handleDownloadEnclosure = async (enclosureId, enclosureName) => {
    let data = await getSasTokenToGetFileFromAzure(enclosureId);
    let url = await getBlobFromAzureByUrl(
      data.download_link,
      data.content_type
    );
    downloadFileByBlobUrl(url, enclosureName);
  };

  const handlePreviewEnclosure = async (enclosureId) => {
    let data = await getSasTokenToGetFileFromAzure(enclosureId);
    let url = await getBlobFromAzureByUrl(
      data.download_link,
      data.content_type
    );
    showFileByBlobUrl(url);
  };

  const getSasTokenToGetFileFromAzure = (enclosureId) => {
    return makeRequest(axiosPrivate, enclosureDownloadUrl(enclosureId));
  };

  const getBlobFromAzureByUrl = async (urlToken, contentType) => {
    try {
      const response = await fetch(urlToken);
      const blob = await response.blob();
      const blobWithType = new Blob([blob], { type: contentType });

      return URL.createObjectURL(blobWithType);
    } catch (error) {
      snackbarAlert.openErrorSnackbarAlert(
        t("snackbar_alert.occurred_error_during_upload_file")
      );
    }
  };

  const saveFile = (file, fileOriName, extraDataToResponse) => {
    const dataToSend = {
      file: file,
      file_ori_name: fileOriName ? fileOriName : file.name,
    };
    return new Promise((resolve, reject) => {
      axiosPrivate
        .post(saveFileUrl, dataToSend, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((result) => {
          if (extraDataToResponse) {
            result.data = { ...extraDataToResponse, ...result.data };
          }
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const addEnclosureToFavorite = (enclosureId) => {
    return makeRequest(
      axiosPrivate,
      enclosureFavoriteForEnclosureUrl(enclosureId),
      {
        method: "POST",
        data: {},
      }
    );
  };

  const deleteEnclosureFromFavorite = (enclosureIds) => {
    return makeRequest(axiosPrivate, enclosureFavoriteUrl, {
      method: "DELETE",
      data: enclosureIds,
    });
  };

  const deleteOfferEnclosureFromDB = (enclosureId) => {
    return makeRequest(axiosPrivate, offerEnclosureUrl(enclosureId), {
      method: "DELETE",
    });
  };
  const getEnclosureFavoriteForUser = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(enclosureFavoriteUrl, searchParams)
    );
  };

  const getEnclousureFilteringData = () => {
    return makeRequest(axiosPrivate, enclosureFilteringDataUrl);
  };

  const generateSASUploadTokens = (enclosuresToUpload) => {
    return makeRequest(axiosPrivate, enclosureUploadSasTokenUrl, {
      method: "POST",
      data: enclosuresToUpload,
    });
  };

  const uploadDataToAzureStorage = async (file, url) => {
    const formData = new FormData();
    formData.append(file.name, file);

    const myHeaders = new Headers();
    myHeaders.append("x-ms-blob-type", "BlockBlob");
    myHeaders.append("Content-Type", "application/octet-stream");

    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "PUT",
        headers: myHeaders,
        body: file,
      })
        .then((response) => {
          if (response.ok) {
            resolve(response);
          }
          reject(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    getEnclosuresForItemData,
    saveNewAttachment,
    updateEnclosureData,
    updateEnclosureTOData,
    updateEnclosurePartData,
    updateEnclosureOfferData,
    getEnclosureTOData,
    handleDownloadEnclosure,
    handlePreviewEnclosure,
    downloadFileByBlobUrl,
    showFileByBlobUrl,
    copyAttachment,
    uploadAttachments,
    saveFile,
    getEnclosureFavoriteForUser,
    addEnclosureToFavorite,
    deleteEnclosureFromFavorite,
    getEnclousureFilteringData,
    saveManyAttachments,
    downloadFile,
    deleteOfferEnclosureFromDB,
    getBlobFromAzureByUrl,
    generateSASUploadTokens,
    uploadDataToAzureStorage,
    getEnclosuresKindPartData
  };
}
