import { Routes, Route, Navigate } from "react-router-dom";

import Layout from "../../wrapper/Layout";

import PersistLogin from "../../auth/PersistLogin";
import RequiredAuth from "../../auth/RequiredAuth";

import FilterRoute from "../FilterRoute/FilterRoute";

import LoginPage from "../../../page/LoginPage";
import TicketOrderManagementPage from "../../../page/TicketOrderManagementPage";
import TicketDetailsPage from "../../../page/TicketDetailsPage";
import OrderDetailsPage from "../../../page/OrderDetailsPage";
import OffersManagmentPage from "../../../page/OffersManagmentPage";
import OrderManagementPage from "../../../page/OrderManagementPage";
import GuaranteePage from "../../../page/GuaranteePage/GuaranteePage";

import MyTicketsPage from "../../../page/MyTicketsPage";
import MyDocumentsPage from "../../../page/MyDocumentsPage";
import MyOrdersPage from "../../../page/MyOrdersPage/MyOrdersPage";
import TaskPage from "../../../page/TaskPage/TaskPage";
import CorrespondencePage from "../../../page/CorrespondencePage/CorrespondencePage";
import HomePage from "../../../page/HomePage/HomePage";
import ExternalLoginPage from "../../../page/ExternalLoginPage";

import RRPanelPage from "../../../page/RRPanelPage";
import RRPanelComplainsPage from "../../../page/RRPanelComplainsPage/RRPanelComplainsPage";
import RRAdminPage from "../../../page/RRAdminPage";

import UserAdminPage from "../../../page/AdminUsersPage";
import AdminTenantsPage from "../../../page/AdminTenantsPage/";

import RcpTimeTrackingPage from "../../../page/RcpTimeTrackingPage";

import TicketsPerTimeReportPage from "../../../page/TicketsPerTimeReportPage";
import TicketsPerLocationReportPage from "../../../page/TicketsPerLocationReportPage";
import TicketsPerTicketOwnerReportPage from "../../../page/TicketsPerTicketOwnerReportPage";
import TicketsPerTicketKReportPage from "../../../page/TicketsPerTicketKReportPage";
import TicketPerResponseTimeReportPage from "../../../page/TicketPerResponseTimeReportPage";

import OrdersByTicketCategoryReportPage from "../../../page/OrdersByTicketCategoryReportPage";
import OrdersBySlaReportPage from "../../../page/OrdersBySlaReportPage";
import OrdersByOrderSkillPage from "../../../page/OrdersBySkillReportPage";
import OrdersByOrderTReportPage from "../../../page/OrdersByOrderTReportPage";
import OrdersByOrderKTReportPage from "../../../page/OrdersByOrderKTReportPage";

import FilterValueService from "../../../services/filterValueService";

import ProtectedRoute from "../../auth/ProtectedRoute/ProtectedRoute";
import {
  IS_K,
  IS_KT,
  IS_OM,
  IS_T,
  IS_TB,
  IS_RR,
  IS_G,
  IS_LEGAL,
  IS_ADMIN,
  IS_WAREHOUSE,
  IS_FINANCE,
  IS_ACCOUNT,
  IS_CONTROLLING,
  IS_POST,
  IS_COMMERCE,
  IS_USERS_ADMIN,
  IS_TENANTS_ADMIN,
  IS_OBJECTS_ADMIN,
  IS_STD_TICKETS_ADMIN,
  IS_AUTO_TICKETS_ADMIN,
  IS_REPORT_FM,
  IS_REPORT_SAFETY,
  IS_WAREHOUSE_ADMIN,
  IS_POST_ADMIN,
  IS_SCM,
} from "../../../helpers/userPermissions";

import RedirectRoute from "../RedirectRoute";
import PlannerPage from "../../../page/PlannerPage/PlannerPage";
import JoinOffersPage from "../../../page/JoinOffersPage/JoinOffersPage";
import OffersRRAcceptPage from "../../../page/OffersRRAcceptPage";
import MyOffersAcceptPage from "../../../page/MyOffersAcceptPage/MyOffersAcceptPage";
import OwnerManagementPage from "../../../page/OwnerManagementPage";
import FeedbackPage from "../../../page/FeedbackPage/FeedbackPage";
import FeedbackManagmentPage from "../../../page/FeedbackManagmentPage";
import StockWarehousePage from "../../../page/StockWarehousePage/StockWarehousePage";
import MaterialPassportPage from "../../../page/MaterialPassportPage/MaterialPassportPage";

import MyOliviaAuth from "../../auth/MyOliviaAuth";
import IsTestingAppWrapper from "../../wrapper/IsTestingAppWrapper/IsTestingAppWrapper";
import AgreementsPage from "../../../page/AgreementsPage";
import GrtReportPage from "../../../page/GrtReportPage";
import AgreementsGuaranteePage from "../../../page/AgreementsGuaranteePage/AgreementsGuaranteePage";
import OWUPage from "../../../page/OWUPage";
import AdminLocationsPage from "../../../page/AdminLocationsPage";
import WarehouseTransactionPage from "../../../page/WarehouseTransactionPage";
import StockBufferPage from "../../../page/StockBufferPage/StockBufferPage";
import PoRegistryPage from "../../../page/PoRegistryPage/PoRegistryPage";
import AdminStdTicketStdOrderPage from "../../../page/AdminStdTicketStdOrderPage";
import PoInvoicePage from "../../../page/PoInvoicePage";
import AdminStdTicketStdOrderAutomatPage from "../../../page/AdminStdTicketStdOrderAutomatPage";
import ReleasesPage from "../../../page/ReleasesPage";
import WarehouseAdminPage from "../../../page/WarehouseAdminPage";
import AdminPermissionsPage from "../../../page/AdminPermissionsPage";
import ServicePassportPage from "../../../page/ServicePassportPage/ServicePassportPage";
import AdminPanelRedirectRoute from "../AdminPanelRedirectRoute";
import ReportsRedirectRoute from "../ReportsRedirectRoute";
import TicketsOpenCloseReportPage from "../../../page/TicketsOpenCloseReportPage";
import TicketsPerLocationAndDayReportPage from "../../../page/TicketsPerLocationAndDayReportPage";
import TicketFeedbackDistributionReportPage from "../../../page/TicketFeedbackDistributionReportPage/TicketFeedbackDistribtutionReportPage";
import TicketNpsDistributionReportPage from "../../../page/TicketNpsDistributionReportPage";
import SubcontractorManagementPage from "../../../page/SubcontractorManagementPage/SubcontractorManagementPage";
import ItemTreePage from "../../../page/ItemTreePage";
import OffersPerLocationReportPage from "../../../page/OffersPerLocationReportPage";
import OffersPerStatusReportPage from "../../../page/OffersPerStatusReportPage";
import OffersPerReportTypeReportPage from "../../../page/OffersPerReportTypeReportPage";
import CorrespondenceAdminPage from "../../../page/CorrespondenceAdminPage/CorrespondenceAdminPage";
import DocsRestrictedPage from "../../../page/DocsRestrictedPage";
import PostDetailsPage from "../../../page/PostDetailsPage";
import RequiredAad from "../../auth/RequiredAad";
import { TENANT_TABLE_FILTER_PREFIX } from "../../other/TenantTableWithToolBar/TableConfig";
import PriceListPage from "../../../page/PriceListPage/PriceListPage";

function Router() {
  return (
    <Routes>
      <Route element={<IsTestingAppWrapper />}>
        <Route element={<Layout />}>
          <Route path="/login/" element={<LoginPage />} />
          <Route path="/login_external/" element={<ExternalLoginPage />} />
          <Route element={<MyOliviaAuth />}>
            <Route element={<PersistLogin />}>
              <Route element={<RequiredAuth />}>
                <Route
                  path="/my_tickets/"
                  element={
                    <FilterRoute
                      pageName="my_tickets"
                      defaultFilters={FilterValueService.getDefaultIsOpenFilter()}
                    >
                      <MyTicketsPage />
                    </FilterRoute>
                  }
                />
                <Route
                  path="/my_documents/"
                  element={
                    <FilterRoute pageName="my_documents">
                      <MyDocumentsPage />
                    </FilterRoute>
                  }
                />

                <Route
                  path="/price_list/"
                  element={
                    <FilterRoute pageName="price_list">
                  <PriceListPage />
                  </FilterRoute>
                  }
                />
                <Route
                  path="/owu/"
                  element={<OWUPage />} />
                <Route
                  element={
                    <ProtectedRoute permissionList={[IS_OM, IS_ADMIN]} />
                  }
                >
                  <Route
                    path="/my_offers/"
                    element={
                      <FilterRoute pageName="my_offers">
                        <MyOffersAcceptPage />
                      </FilterRoute>
                    }
                  />
                </Route>
                <Route
                  element={<ProtectedRoute permissionList={[IS_K, IS_ADMIN]} />}
                >
                  <Route
                    path="/ticket_orders/"
                    element={
                      <FilterRoute
                        pageName="ticket_orders"
                        defaultFilters={FilterValueService.getDefaultIsOpenFilter()}
                      >
                        <TicketOrderManagementPage />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/offers/"
                    element={
                      <FilterRoute pageName="offers">
                        <OffersManagmentPage />
                      </FilterRoute>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      permissionList={[IS_KT, IS_K, IS_TB, IS_ADMIN]}
                    />
                  }
                >
                  <Route
                    path="/join_offers/"
                    element={
                      <FilterRoute pageName="join_offers">
                        <JoinOffersPage pageName="join_offers" />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/service_passport/"
                    element={
                      <FilterRoute pageName="service_passport">
                        <ServicePassportPage pageName="service_passport" />
                      </FilterRoute>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute permissionList={[IS_RR, IS_ADMIN]} />
                  }
                >
                  <Route
                    path="/accept_offers/"
                    element={
                      <FilterRoute pageName="accept_offers">
                        <OffersRRAcceptPage pageName="accept_offers" />
                      </FilterRoute>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      permissionList={[
                        IS_LEGAL,
                        IS_CONTROLLING,
                        IS_FINANCE,
                        IS_ACCOUNT,
                        IS_COMMERCE,
                        IS_ADMIN,
                      ]}
                    />
                  }
                >
                  <Route
                    path="/owners/"
                    element={
                      <FilterRoute
                        pageName="owners"
                        defaultFilters={FilterValueService.getDefaulFilterForActiveOwners()}
                      >
                        <OwnerManagementPage pageName="owners" />
                      </FilterRoute>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute permissionList={[IS_K, IS_KT, IS_ADMIN]} />
                  }
                >
                  <Route
                    path="/subcontractors/"
                    element={
                      <FilterRoute
                        pageName="subcontractors"
                        defaultFilters={FilterValueService.getDefaulFilterForActiveSubcontractors()}
                      >
                        <SubcontractorManagementPage pageName="subcontractors" />
                      </FilterRoute>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      permissionList={[
                        IS_LEGAL,
                        IS_CONTROLLING,
                        IS_COMMERCE,
                        IS_ADMIN,
                      ]}
                    />
                  }
                >
                  <Route
                    path="/agreements/"
                    element={
                      <FilterRoute
                        pageName="agreements"
                        defaultFilters={FilterValueService.getDefaulFilterForActiveAgreements()}
                      >
                        <AgreementsPage pageName="agreements" />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/agreement_guarantees/"
                    element={
                      <FilterRoute pageName="agreement_guarantees">
                        <AgreementsGuaranteePage pageName="agreement_guarantees" />
                      </FilterRoute>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute permissionList={[IS_KT, IS_K, IS_ADMIN]} />
                  }
                >
                  <Route
                    path="/orders/"
                    element={
                      <FilterRoute
                        pageName="orders"
                        defaultFilters={FilterValueService.getDefaultIsOpenFilter()}
                      >
                        <OrderManagementPage />
                      </FilterRoute>
                    }
                  />
                </Route>

                <Route
                  element={
                    <ProtectedRoute
                      permissionList={[IS_KT, IS_ADMIN, IS_K, IS_TB]}
                    />
                  }
                >
                  <Route
                    path="/my_orders/"
                    element={
                      <FilterRoute
                        pageName="my_orders"
                        defaultFilters={FilterValueService.getDefaultIsOpenFilter()}
                      >
                        <MyOrdersPage />
                      </FilterRoute>
                    }
                  />
                </Route>

                <Route
                  element={
                    <ProtectedRoute permissionList={[IS_T, IS_TB, IS_ADMIN]} />
                  }
                >
                  <Route
                    path="/my_tasks/"
                    element={
                      <FilterRoute
                        pageName="my_tasks"
                        defaultFilters={FilterValueService.getDefaulDateWithIsOpenFilter()}
                      >
                        <TaskPage />
                      </FilterRoute>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      permissionList={[IS_LEGAL, IS_POST, IS_ADMIN]}
                    />
                  }
                >
                  <Route
                    path="/correspondence/"
                    element={
                      <FilterRoute pageName="correspondence">
                        <CorrespondencePage pageName="correspondence" />
                      </FilterRoute>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute permissionList={[IS_POST_ADMIN, IS_ADMIN]} />
                  }
                >
                  <Route
                    path="/correspondence_admin/"
                    element={
                      <CorrespondenceAdminPage pageName="correspondence_admin" />
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute permissionList={[IS_KT, IS_ADMIN]} />
                  }
                >
                  <Route
                    path="/planner/"
                    element={
                      <FilterRoute pageName="planner_page">
                        <PlannerPage pageName="planner_page" />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/task_management/"
                    element={
                      <FilterRoute
                        pageName="task_management"
                        defaultFilters={FilterValueService.getDefaulDateFilter()}
                      >
                        <TaskPage />
                      </FilterRoute>
                    }
                  />
                </Route>

                <Route
                  element={<ProtectedRoute permissionList={[IS_G, IS_ADMIN]} />}
                >
                  <Route
                    path="/guarantee/"
                    element={
                      <FilterRoute pageName="guarantee">
                        <GuaranteePage />
                      </FilterRoute>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute permissionList={[IS_ADMIN, IS_ACCOUNT]} />
                  }
                >
                  <Route path="/grt_report/" element={<GrtReportPage />} />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      permissionList={[
                        IS_ADMIN,
                        IS_KT,
                        IS_REPORT_SAFETY,
                        IS_REPORT_FM,
                      ]}
                    />
                  }
                >
                  <Route
                    path="/reports_redirect_page/"
                    element={<ReportsRedirectRoute />}
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      permissionList={[IS_ADMIN, IS_REPORT_SAFETY]}
                    />
                  }
                >
                  <Route
                    path="/tickets_per_time_report/"
                    element={
                      <FilterRoute pageName="tickets_per_time_report">
                        <TicketsPerTimeReportPage />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/tickets_per_owner_report/"
                    element={
                      <FilterRoute pageName="tickets_per_owner_report">
                        <TicketsPerTicketOwnerReportPage />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/tickets_per_location_report/"
                    element={
                      <FilterRoute pageName="tickets_per_location_report">
                        <TicketsPerLocationReportPage />
                      </FilterRoute>
                    }
                  />

                  <Route
                    path="/orders_by_ticket_category_report/"
                    element={
                      <FilterRoute pageName="orders_by_ticket_category_report">
                        <OrdersByTicketCategoryReportPage />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/orders_by_sla_report/"
                    element={
                      <FilterRoute pageName="orders_by_sla_report">
                        <OrdersBySlaReportPage />
                      </FilterRoute>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute permissionList={[IS_ADMIN, IS_REPORT_FM]} />
                  }
                >
                  <Route
                    path="/tickets_per_location_day_report/"
                    element={
                      <FilterRoute pageName="tickets_per_location_day_report">
                        <TicketsPerLocationAndDayReportPage />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/tickets_open_close_report/"
                    element={
                      <FilterRoute pageName="tickets_open_close_report">
                        <TicketsOpenCloseReportPage />
                      </FilterRoute>
                    }
                  />

                  <Route
                    path="/tickets_per_feedback_report/"
                    element={
                      <FilterRoute pageName="tickets_per_feedback_report">
                        <TicketFeedbackDistributionReportPage />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/tickets_per_nps_report/"
                    element={
                      <FilterRoute pageName="tickets_per_nps_report">
                        <TicketNpsDistributionReportPage />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/tickets_by_ticket_k_report/"
                    element={
                      <FilterRoute pageName="tickets_by_ticket_k_report">
                        <TicketsPerTicketKReportPage />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/tickets_by_response_time_report/"
                    element={
                      <FilterRoute pageName="tickets_by_response_time_report">
                        <TicketPerResponseTimeReportPage />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/orders_by_order_kt_report/"
                    element={
                      <FilterRoute pageName="orders_by_order_kt_report">
                        <OrdersByOrderKTReportPage />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/orders_by_order_t_report/"
                    element={
                      <FilterRoute pageName="orders_by_order_t_report">
                        <OrdersByOrderTReportPage />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/orders_by_skill_report/"
                    element={
                      <FilterRoute pageName="orders_by_skill_report">
                        <OrdersByOrderSkillPage />
                      </FilterRoute>
                    }
                  />

                  <Route
                    path="/offers_per_location_report/"
                    element={
                      <FilterRoute pageName="offers_per_location_report">
                        <OffersPerLocationReportPage />
                      </FilterRoute>
                    }
                  />

                  <Route
                    path="/offers_per_status_report/"
                    element={
                      <FilterRoute pageName="offers_per_status_report">
                        <OffersPerStatusReportPage />
                      </FilterRoute>
                    }
                  />

                  <Route
                    path="/offers_per_report_type_report/"
                    element={
                      <FilterRoute pageName="offers_per_report_type_report">
                        <OffersPerReportTypeReportPage />
                      </FilterRoute>
                    }
                  />
                </Route>

                <Route
                  element={
                    <ProtectedRoute permissionList={[IS_ADMIN, IS_RR]} />
                  }
                >
                  <Route
                    path="/feedback/"
                    element={
                      <FilterRoute
                        pageName="feedback"
                        localStorageKey={"rr-panel-tool-bar"}
                        defaultFilters={[
                          {
                            filter_prefix: undefined,
                            filter_value: {
                              tenant_rr_visibility: true,
                            },
                          },
                        ]}
                      >
                        <FeedbackManagmentPage />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/rr_panel/"
                    element={
                      <FilterRoute
                        pageName="rr_panel_page"
                        localStorageKey={"rr-panel-tool-bar"}
                        defaultFilters={[
                          {
                            filter_value: {
                              tenant_rr_visibility: true,
                              is_open: false,
                            },
                          },
                        ]}
                      >
                        <RRPanelPage />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/rr_panel_complains/"
                    element={
                      <FilterRoute
                        pageName="rr_panel_complains"
                        localStorageKey={"rr-panel-tool-bar"}
                        defaultFilters={[
                          {
                            filter_value: {
                              tenant_rr_visibility: true,
                              is_open: false,
                            },
                          },
                        ]}
                      >
                        <RRPanelComplainsPage />
                      </FilterRoute>
                    }
                  />
                  <Route
                    path="/rr_admin/"
                    element={
                      <FilterRoute
                        pageName="rr_admin_page"
                        defaultFilters={[
                          {
                            filter_prefix: "contacts__user_tenant",
                            filter_value: {
                              user__user_external_system: "my_olivia",
                            },
                          },
                        ]}
                      >
                        <RRAdminPage />
                      </FilterRoute>
                    }
                  />
                </Route>

                <Route
                  element={
                    <ProtectedRoute permissionList={[IS_KT, IS_ADMIN]} />
                  }
                >
                  <Route
                    path="/rcp_time_tracking/"
                    element={
                      <FilterRoute
                        pageName="rcp_time_tracking"
                        defaultFilters={FilterValueService.getFirstAndLastDayOfCurrentMonthFilter(
                          {
                            dayStartKey: "day_start",
                            dayEndKey: "day_end",
                            filterPrefixesList: ["search_bar", "rcp_time"],
                          }
                        )}
                      >
                        <RcpTimeTrackingPage />
                      </FilterRoute>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      permissionList={[
                        IS_ADMIN,
                        IS_USERS_ADMIN,
                        IS_TENANTS_ADMIN,
                        IS_OBJECTS_ADMIN,
                        IS_STD_TICKETS_ADMIN,
                        IS_AUTO_TICKETS_ADMIN,
                      ]}
                    />
                  }
                >
                  <Route
                    path="/admin_panel_redirect_page/"
                    element={<AdminPanelRedirectRoute />}
                  />
                </Route>

                <Route
                  element={
                    <ProtectedRoute
                      permissionList={[IS_ADMIN, IS_USERS_ADMIN]}
                    />
                  }
                >
                  <Route
                    path="/admin_users/"
                    element={
                      <FilterRoute
                        pageName="admin_users"
                        defaultFilters={FilterValueService.getDefaulFilterForVisibleUserTenant(
                          {
                            fieldName: "uste_rr_visibility",
                            filterPrefix: "user_tenant",
                          }
                        )}
                      >
                        <UserAdminPage />
                      </FilterRoute>
                    }
                  />
                </Route>

                <Route
                  element={
                    <ProtectedRoute
                      permissionList={[IS_ADMIN, IS_TENANTS_ADMIN]}
                    />
                  }
                >
                  <Route
                    path="/admin_tenants/"
                    element={
                      <FilterRoute
                        pageName="admin_tenants"
                        defaultFilters={FilterValueService.getDefaulFilterForActiveTenants()}
                      >
                        <AdminTenantsPage />
                      </FilterRoute>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      permissionList={[IS_ADMIN, IS_OBJECTS_ADMIN]}
                    />
                  }
                >
                  <Route
                    path="/admin_locations/"
                    element={
                      <FilterRoute pageName="admin_locations">
                        <AdminLocationsPage />
                      </FilterRoute>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      permissionList={[IS_ADMIN, IS_STD_TICKETS_ADMIN]}
                    />
                  }
                >
                  <Route
                    path="/admin_std_tickets_orders/"
                    element={
                      <FilterRoute pageName="admin_std_tickets_orders">
                        <AdminStdTicketStdOrderPage />
                      </FilterRoute>
                    }
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoute
                      permissionList={[IS_ADMIN, IS_AUTO_TICKETS_ADMIN]}
                    />
                  }
                >
                  <Route
                    path="/admin_std_tickets_orders_automat/"
                    element={
                      <FilterRoute pageName="admin_std_tickets_orders_automat">
                        <AdminStdTicketStdOrderAutomatPage />
                      </FilterRoute>
                    }
                  />

                  <Route
                    path="/admin_permissions/"
                    element={
                      <FilterRoute pageName="admin_permissions">
                        <AdminPermissionsPage />
                      </FilterRoute>
                    }
                  />
                </Route>
                <Route
                  path="/feedback/:ticketId/rate/:rateId"
                  element={<FeedbackPage />}
                />
                <Route path="*" element={<Navigate to="/" />} />
                <Route path="/redirect_page/" element={<RedirectRoute />} />

                <Route
                  path="/ticket_details/:ticketId"
                  element={<TicketDetailsPage />}
                />
                <Route
                  path="/order_details/:orderId"
                  element={<OrderDetailsPage />}
                />
              </Route>
            </Route>
          </Route>
          <Route element={<ProtectedRoute permissionList={[IS_ADMIN, IS_G]} />}>
            <Route
              path="/guarantee/"
              element={
                <FilterRoute pageName="guarantee">
                  <GuaranteePage />
                </FilterRoute>
              }
            />
          </Route>

          <Route
            element={
              <ProtectedRoute
                permissionList={[
                  IS_WAREHOUSE,
                  IS_SCM,
                  IS_KT,
                  IS_K,
                  IS_TB,
                  IS_ADMIN,
                ]}
              />
            }
          >
            <Route
              path="/material_passport/"
              element={<MaterialPassportPage pageName="material_passport" />}
            />
            <Route
              path="/item_tree/"
              element={<ItemTreePage pageName="item_tree" />}
            />
          </Route>

          <Route
            element={
              <ProtectedRoute permissionList={[IS_WAREHOUSE, IS_ADMIN]} />
            }
          >
            <Route
              path="/stocks/"
              element={
                <FilterRoute pageName="stocks">
                  <StockWarehousePage />
                </FilterRoute>
              }
            />
            <Route
              path="/transactions/"
              element={
                <WarehouseTransactionPage pageName="warehouse_transactions" />
              }
            />
          </Route>

          <Route
            element={
              <ProtectedRoute
                permissionList={[IS_WAREHOUSE, IS_SCM, IS_ADMIN]}
              />
            }
          >
            <Route
              path="/buffers/"
              element={<StockBufferPage pageName="buffers" />}
            />
          </Route>

          <Route
            element={<ProtectedRoute permissionList={[IS_SCM, IS_ADMIN]} />}
          >
            <Route
              path="/po_registry/"
              element={<PoRegistryPage pageName="po_registry" />}
            />
            <Route
              path="/po_invoices/"
              element={<PoInvoicePage pageName="po_invoices" />}
            />
            {/* <Route
              path="/item_tree/"
              element={<ItemTreePage pageName="item_tree" />}
            /> */}
          </Route>

          <Route
            element={
              <ProtectedRoute permissionList={[IS_WAREHOUSE_ADMIN, IS_ADMIN]} />
            }
          >
            <Route
              path="/warehouse_admin/"
              element={<WarehouseAdminPage pageName="warehouse_admin" />}
            />
          </Route>

          <Route
            element={
              <ProtectedRoute permissionList={[IS_COMMERCE, IS_ADMIN]} />
            }
          >
            <Route
              path="/releases/"
              element={
                <FilterRoute
                  pageName="releases"
                  defaultFilters={FilterValueService.getDefaultIsOpenFilter()}
                >
                  <ReleasesPage />
                </FilterRoute>
              }
            />
          </Route>
          <Route
            element={
              <ProtectedRoute permissionList={[IS_ADMIN, IS_KT, IS_K, IS_TB]} />
            }
          >
            <Route
              path="/docs_restricted/"
              element={
                <FilterRoute pageName="docs_restricted">
                  <DocsRestrictedPage />
                </FilterRoute>
              }
            />
          </Route>
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/redirect_page/" element={<RedirectRoute />} />
      </Route>
      <Route element={<MyOliviaAuth />}>
        <Route element={<PersistLogin />}>
          <Route element={<RequiredAuth />}>
            <Route element={<IsTestingAppWrapper />}>
              <Route path="/" element={<HomePage />} />
              <Route element={<RequiredAad />}>
                <Route
                  path="/correspondence_post/:postId"
                  element={<PostDetailsPage pageName="post_details" />}
                />
              </Route>

            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default Router;
