import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import AutocompleteField from "../../field/AutocompleteField";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../hooks/useForm";
import { isEmptyValue } from "../../../helpers/methods";
import useCorrespondenceService from "../../../services/correspondenceService";
import DateField from "../../field/DateField/DateField";
import CheckboxField from "../../base/CheckboxField/checkboxField";
import PostNotificationTable from "../../table/PostNotificationTable";
import BaseBox from "../../base/BaseBox/baseBox";
import FileUploadList from "../../other/FileUploadList";
import useDocsData from "../../../hooks/useDocsData";
import useFileService from "../../../services/fileService";
import useFileOnMemoryData from "../../../hooks/useFileOnMemoryData";
import useUserService from "../../../services/userService";

const PostForm = (props) => {
  const { t } = useTranslation();
  const currentDate = new Date();

  const { getSideAdministrationList, getPostSubcategoryList, getPostParent, getPostDefaultStatus } = useCorrespondenceService();
  const { getTenantAddress } = useUserService();
  const getTenantAddressFn = useAsyncFn(getTenantAddress)
  const getPostSubcategoryListFn = useAsyncFn(getPostSubcategoryList)
  const getPostParentFn = useAsyncFn(getPostParent)
  const [isAdministration, setIsAdministration] = useState(false);
  const [isTenant, setIsTenant] = useState(false);
  const postDefaultStatus = useAsync(() => getPostDefaultStatus({ post_in: props.postIn }));
  const getSideAdministrationListFn = useAsyncFn(getSideAdministrationList)
  const [sideAdministrationSelectList, setSideAdministrationSelectList] = useState(props.formValue ? props.autocompleteData.side_administration : []);
  const [postSubcategorySelectList, setPostSubcategorySelectList] = useState(props.formValue ? props.autocompleteData.post_subcat : [])
  const [postStatusSelectList, setPostStatusSelectList] = useState(props.formValue ? props.autocompleteData.post_status : []);
  const [recipients, setRecipients] = useState([]);

  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions,
  } = useForm({
    post_in: props.postIn,
    post_date_in: currentDate,
    post_registration_date: currentDate,
    post_archives_date: currentDate,
  });



  useEffect(() => {
    if (
      !props.formValue?.post_status &&
      postDefaultStatus.value &&
      postDefaultStatus.value.length > 0
    ) {
      const postStatusId = postDefaultStatus.value[0].id;
      setFormValue(prevFormValue => ({
        ...prevFormValue,
        post_status: postStatusId,
      }));
    }
  }, [props.formValue, postDefaultStatus.value, setFormValue]);

  useEffect(() => {
    if (props.formValue) {
      setRecipients(props.formValue.recipients);
      setFormValue(props.formValue);
    }
  }, [props.formValue]);

  useEffect(() => {
    if (props.formValue?.post_subcat_category) {
      setPostSubcategorySelectList(getPostSubcategoryListFn.execute({ post_category: props.formValue.post_subcat_category.id })
        .then((res) => setPostSubcategorySelectList(res)));
    }
  }, [props.formValue?.post_subcat_category]);


  const updatePostStatusList = () => {
    if (typeof props.postIn === 'boolean') {

      const filteredList = props.autocompleteData.post_status.filter((item) => {
        const included = item.post_status_in === props.postIn;
        return included;
      });
      setPostStatusSelectList(filteredList);
    } else {
      setPostStatusSelectList([]);
    }
  };

  useEffect(() => {
    updatePostStatusList();
  }, [props.postIn, props.autocompleteData.post_status]);


  const handleAddRecipient = (recipient) => {
    setRecipients((prevRecipients) => [...prevRecipients, recipient]);
  };

  const handleAutocompleteChange = (e, value_object, value_key, state_value_name) => {
    const resetFormValue = (updates) => {
      setFormValue((formValue) => ({ ...formValue, ...updates }));
    };

    const updatePostSubcategory = () => {
      resetFormValue({ post_subcat: undefined });
      if (value_object.id) {
        getPostSubcategoryListFn.execute({ post_category: value_object.id })
          .then((res) => setPostSubcategorySelectList(res));
      } else {
        setPostSubcategorySelectList([]);
        resetFormValue({ post_subcat: undefined });
      }
    };

    const updateSideAdministration = () => {
      setIsAdministration(value_object.side_type_input_administration);
      setIsTenant(value_object.side_type_is_tenant);
      resetFormValue({
        post_side_address: undefined,
        post_side_name: undefined,
      });

      if (value_object.id && !value_object.side_type_is_tenant) {
        getSideAdministrationListFn.execute({ side_type: value_object.id })
          .then((res) => setSideAdministrationSelectList(res));
      }
      else {
        setSideAdministrationSelectList([]);
        resetFormValue({
          post_side_address: undefined,
          post_side_name: undefined,
        });
      }
    };

    const updateSideAdministrationFullAddress = () => {
      if (value_object.id && isTenant) {
        getTenantAddressFn.execute(value_object.id)
          .then(selectedTenantAddress => {
            if (selectedTenantAddress) {
              resetFormValue({
                post_side_address: selectedTenantAddress[0].full_address,
              });
            }
          })
          .catch(error => {
            console.error("Error fetching tenant address:", error);
          });
      }
      if (value_object.id) {
        const selectedAdministration = sideAdministrationSelectList.find(admin => admin.id === value_object.id);
        if (selectedAdministration) {
          resetFormValue({
            post_side_address: selectedAdministration.full_address,
          });
        }
      }
      else {
        resetFormValue({ post_side_address: undefined });
      }
    };

    resetFormValue({ [state_value_name]: undefined });
    onChangeAutocompleteFieldWithObjectOptions(e, value_object, value_key, state_value_name);


    switch (state_value_name) {
      case 'post_subcat_category':
        updatePostSubcategory();
        break;
      case 'post_side_type':
        updateSideAdministration();
        break;
      case 'post_side_name':
        updateSideAdministrationFullAddress();
        break;
      default:
        break;
    }
  };

  const onChangeCheckbox = useCallback((e) => {
    const { name, checked } = e.target;
    setFormValue((formValue) => ({ ...formValue, [name]: checked }));

    return { name, value: checked };
  }, []);

  const onBlurTextInput = useCallback(async (e) => {
    const { name, value } = e.target;

    if (name === "post_decree" && value) {
      const response = await getPostParentFn.execute({ decree_number: value });
      if (response) {
        setFormValue((prevFormValue) => ({ ...prevFormValue, post_parent: response.id }));
      } else {
        setFormValue((prevFormValue) => ({ ...prevFormValue, post_parent: undefined }));
      }
    }
  }, [getPostParentFn]);


  const [
    docsPosts,
    onAddDocsPost,
    onUpdateDocsPost,
    onDeleteDocsPost,
    prepareDocsPostsToSend
  ] = useFileOnMemoryData("docs");

  const {
    docs,
    isDocsLoading,
    hideDocs,
    onDownloadDoc,
    onPreviewDoc,
    refetchDocs,
  } = useDocsData(props.postId, "post");



  const handleFormSubmit = async () => {
    let preparedDocsPosts = [];

    if (docsPosts) {
      preparedDocsPosts = prepareDocsPostsToSend(docsPosts);
    }

    let selectedAdministration = null;
    if (isTenant) {
      selectedAdministration = props.autocompleteData.post_tenant?.find(admin => admin.id === formValue.post_side_name);
    } else {
      selectedAdministration = sideAdministrationSelectList?.find(admin => admin.id === formValue.post_side_name);
    }

    const postSideName = isTenant
      ? (selectedAdministration ? selectedAdministration.tenant_short_name : formValue.post_side_name)
      : (selectedAdministration ? selectedAdministration.full_name : formValue.post_side_name);

    const updatedFormValue = {
      ...formValue,
      docs_posts: preparedDocsPosts,
      recipients: recipients,
      post_side_name: postSideName,
    };

    props.onSubmit(updatedFormValue);
  };
  const { downloadFileByBlobUrl } = useFileService();

  const onDownloadEnclosure = (enclosureId, enclosureName, index) => {
    const enclosure = docsPosts[index];
    downloadFileByBlobUrl(enclosure.blob_url, enclosureName);
  };

  const requiredFields = [
    "post_title",
    "post_subcat_category",
    "post_subcat",
    // "post_date_in",
    // "postrec_info_date",
    // "post_owner",
    "post_side_type",
    // "post_side_name",
    // "post_side_address",
    // "post_delivery",
    // "post_status"
  ];

  const isFormValid = () => {
    const areRequiredFieldsValid = requiredFields.every(
      (fieldName) => !isEmptyValue(formValue[fieldName])
    );

    const areRecipientsValid = Array.isArray(recipients) && recipients.length > 0;
    const areRecipientsTypesValid = recipients.every(
      (recipient) => recipient.postrec_person_type !== null
    );

    return areRequiredFieldsValid && areRecipientsValid && areRecipientsTypesValid;
  };

  const showLoading = () => props.autocompleteData.loading;

  if (showLoading()) return <LoaderWrapper showLoader={true} />;
  return (
    <Grid container spacing={2}>

      <Grid item xs={2}>
        <TextFieldFormControl
          name="post_title"
          label={t("table.correspondence.post_title")}
          value={formValue["post_title"]}
          onChange={onChange}
          disabled={props.readOnly}
          required
        />
      </Grid>
      <Grid item xs={2}>
        <AutocompleteField
          name="post_subcat_category"
          label={t("table.correspondence.shipment_category")}
          value={formValue["post_subcat_category"]}
          options={props.autocompleteData.post_subcat_category}
          isObjectOption={true}
          optionLabelKey={"post_cat_name"}
          addNewValue={false}
          onChange={handleAutocompleteChange}
          required
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={2}>
        <AutocompleteField
          name="post_subcat"
          label={t("form.post_form.shipment_subcategory")}
          value={formValue["post_subcat"]}
          options={postSubcategorySelectList}
          isObjectOption={true}
          optionLabelKey={"post_subcat_name"}
          addNewValue={false}
          onChange={onChangeAutocompleteFieldWithObjectOptions}
          required
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={2}>
        <TextFieldFormControl
          name="post_decree"
          label={t("table.correspondence.document_nr")}
          value={formValue["post_decree"]}
          onChange={onChange}
          disabled={props.readOnly}
          onBlur={onBlurTextInput}
        // required
        />
      </Grid>
      <Grid item xs={2}>
        <AutocompleteField
          name="post_parent"
          label={t("table.correspondence.reference")}
          value={formValue["post_parent"]}
          options={props.autocompleteData.post_parent}
          optionLabelKey={"post_title"}
          onChange={onChangeAutocompleteFieldWithObjectOptions}
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={2}>
        <DateField
          name="post_date_in"
          label={props.postIn ? t("table.correspondence.arrival_date") : t("table.correspondence.delivery_date")}
          value={formValue["post_date_in"]}
          onChange={onChangeDate}
          readOnly={props.readOnly}
        // required
        />
      </Grid>
      <Grid item xs={2}>
        <AutocompleteField
          name="post_owner"
          label={t("table.correspondence.owner_short_name")}
          value={formValue["post_owner"]}
          options={props.autocompleteData.post_owner}
          isObjectOption={true}
          optionLabelKey={"owner_short_name"}
          addNewValue={false}
          onChange={handleAutocompleteChange}
          disabled={props.readOnly}
        // required
        />
      </Grid>
      <Grid item xs={2}>
        <AutocompleteField
          name="post_side_type"
          label={props.postIn ? t("table.correspondence.sender_type") : t("table.correspondence.recipient_type")}
          value={formValue["post_side_type"]}
          options={props.autocompleteData.post_side_type}
          isObjectOption={true}
          optionLabelKey={"side_type_name"}
          addNewValue={false}
          onChange={handleAutocompleteChange}
          disabled={props.readOnly}
          required
        />
      </Grid>
      {!isAdministration && isTenant ? (
        <>
          <Grid item xs={2}>
            <AutocompleteField
              name="post_side_name"
              label={props.postIn ? t("table.correspondence.sender_body_name") : t("table.correspondence.recipient_body_name")}
              value={formValue["post_side_name"]}
              options={props.autocompleteData.post_tenant}
              isObjectOption={true}
              optionLabelKey={"tenant_short_name"}
              addNewValue={false}
              onChange={handleAutocompleteChange}
              disabled={props.readOnly}
              required
            />
          </Grid>
          <Grid item xs={2}>
            <TextFieldFormControl
              name="post_side_address"
              label={props.postIn ? t("table.correspondence.sender_body_address") : t("table.correspondence.recipient_body_address")}
              value={formValue["post_side_address"]}
              onChange={onChange}
              disabled={true}
              required
            />
          </Grid>
        </>
      ) : isAdministration ? (
        <>
          <Grid item xs={2}>
            <AutocompleteField
              name="post_side_name"
              label={props.postIn ? t("table.correspondence.sender_body_name") : t("table.correspondence.recipient_body_name")}
              value={formValue["post_side_name"]}
              options={sideAdministrationSelectList}
              isObjectOption={true}
              optionLabelKey={"full_name"}
              addNewValue={false}
              onChange={handleAutocompleteChange}
              disabled={props.readOnly}
              required
            />
          </Grid>
          <Grid item xs={2}>
            <TextFieldFormControl
              name="post_side_address"
              label={props.postIn ? t("table.correspondence.sender_body_address") : t("table.correspondence.recipient_body_address")}
              value={formValue["post_side_address"]}
              onChange={onChange}
              disabled={props.readOnly}
              required
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={2}>
            <TextFieldFormControl
              name="post_side_name"
              label={props.postIn ? t("table.correspondence.sender_name") : t("table.correspondence.recipient_name")}
              value={formValue["post_side_name"]}
              onChange={onChange}
              disabled={props.readOnly}
            />
          </Grid>
          <Grid item xs={2}>
            <TextFieldFormControl
              name="post_side_address"
              label={props.postIn ? t("table.correspondence.sender_address") : t("table.correspondence.recipient_address")}
              value={formValue["post_side_address"]}
              onChange={onChange}
              disabled={props.readOnly}
            />
          </Grid>
        </>
      )}

      <Grid item xs={2}>
        <AutocompleteField
          name="post_delivery"
          label={t("table.correspondence.delivery_type")}
          value={formValue["post_delivery"]}
          options={props.autocompleteData.post_delivery}
          isObjectOption={true}
          optionLabelKey={"delivery_name"}
          addNewValue={false}
          onChange={onChangeAutocompleteFieldWithObjectOptions}
          disabled={props.readOnly}
        // required
        />
      </Grid>
      <Grid item xs={2}>
        <DateField
          label={t("table.correspondence.deadline")}
          onChange={onChangeDate}
          name={"post_event_date"}
          value={formValue["post_event_date"]}
          readOnly={props.readOnly}
        // isInvalid={isStartDateInvalid}
        // helperText={startDateHelperText}
        // required
        />
      </Grid>

      <Grid item xs={2}>
        <DateField
          name="postrec_info_date"
          label={t("table.correspondence.notification_date")}
          value={formValue["postrec_info_date"]}
          onChange={onChangeDate}
          readOnly={props.readOnly}
        //required
        />
      </Grid>
      <Grid item xs={2}>
        <DateField
          name="post_registration_date"
          label={t("table.correspondence.registration_date")}
          value={formValue["post_registration_date"]}
          onChange={onChangeDate}
          readOnly={props.readOnly}
        // required
        />
      </Grid>
      <Grid item xs={2}>
        <AutocompleteField
          name="post_archives_location"
          label={t("table.correspondence.archiving_place")}
          value={formValue["post_archives_location"]}
          options={props.autocompleteData.post_archives_location}
          isObjectOption={true}
          optionLabelKey={"post_arch_name"}
          addNewValue={false}
          onChange={onChangeAutocompleteFieldWithObjectOptions}
          disabled={props.readOnly}
        // required
        />
      </Grid>
      <Grid item xs={2}>
        <AutocompleteField
          name="post_status"
          label={t("table.correspondence.status")}
          value={formValue["post_status"]}
          options={postStatusSelectList}
          isObjectOption={true}
          optionLabelKey={"post_status_name"}
          addNewValue={false}
          onChange={onChangeAutocompleteFieldWithObjectOptions}
          disabled={props.readOnly}
        // required
        />
      </Grid>
      <Grid item xs={1}>
        <CheckboxField
          name="post_zpo"
          label={t("table.correspondence.ZPO")}
          checked={formValue["post_zpo"]}
          value={formValue["post_zpo"]}
          onChange={onChangeCheckbox}
          disabled={props.readOnly}
        />
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={props.xs ? 5.95 : 3}>
        <TextFieldFormControl
          name="post_note"
          label={t("table.correspondence.note")}
          value={formValue["post_note"]}
          onChange={onChange}
          disabled={props.readOnly}
          multiline
          rows={2.6}
          resize={"vertical"}
          inputProps={{ resize: "vertical" }}
        />
      </Grid>
      <Grid item xs={props.xs ? 0 : 5}>
        {!props.readOnly && (
          <PostNotificationTable
            onChangeAutocompleteFieldWithObjectOptions={onChangeAutocompleteFieldWithObjectOptions}
            handleAutocompleteChange={handleAutocompleteChange}
            onChange={onChange}
            setRecipients={setRecipients}
            recipients={recipients}
            handleAddRecipient={handleAddRecipient}
            filteringData={props.filteringData}
            autocompleteData={props.autocompleteData}
            postIn={props.postIn}
            readOnly={props.readOnly}
          />)}
      </Grid>
      <Grid item xs={props.xs ? 5.95 : 3}>
        <BaseBox>
          {props.formValue && props.formValue.id ? (
            <FileUploadList
              addEnclosureButtonProps={{ size: "mini" }}
              enclosures={docs}
              fileType={"docs"}
              onPreviewEnclosure={onPreviewDoc}
              onDownloadEnclosure={onDownloadDoc}
              onDeleteEnclosure={hideDocs}
              canRemoveEnclosures={true}
              refetchDocs={refetchDocs}
              readOnly={isDocsLoading || props.readOnly}
              post={props.postId}
              availableDocsRestrictedTypes={[]}

            />
          ) : (<FileUploadList
            addEnclosureButtonProps={{ size: "mini" }}
            enclosures={docsPosts}
            onDownloadEnclosure={onDownloadEnclosure}
            onAddFile={onAddDocsPost}
            onDeleteEnclosure={onDeleteDocsPost}
            onUpdateEnclosure={onUpdateDocsPost}
            showDetailsButton={false}
            canRemoveEnclosures={true}
            fileType={"docs"}
            filesOnMemory={true}
            multiple={true}
            readOnly={isDocsLoading || props.readOnly}
            availableDocsRestrictedTypes={[]}

          />)
          }
        </BaseBox>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={3}></Grid>
      {!props.readOnly && (
        <Grid item xs={6}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={props.isLoading}
            disabled={!isFormValid()}
            onClick={handleFormSubmit}
          >
            {props.formValue && props.formValue.id ? t("save") : t("create")}
          </LoadingButton>
        </Grid>
      )}
    </Grid>

  );
};
PostForm.propTypes = {
  formValue: PropTypes.object,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  onOpenAddNewOwner: PropTypes.func,
  postIn: PropTypes.bool.isRequired,
};

PostForm.defaultProps = {
  readOnly: false,
};

export default PostForm;
